import { createRouter, createWebHistory } from 'vue-router';
const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "admin" */ '../views/fontend/Index.vue'),
  },
  {
    path: '/about/origin',
    name: 'origin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/fontend/aboutOrigin.vue'),
  },
  {
    path: '/about/serve',
    name: 'serve',
    component: () => import(/* webpackChunkName: "admin" */ '../views/fontend/aboutServe.vue'),
  },
  {
    path: '/about/organize',
    name: 'organize',
    component: () => import(/* webpackChunkName: "admin" */ '../views/fontend/aboutOrganize.vue'),
  },
  {
    path: '/about/quession',
    name: 'quession',
    component: () => import(/* webpackChunkName: "admin" */ '../views/fontend/aboutQuession.vue'),
  },
  {
    path: '/album/master/:activityId',
    name: 'master',
    props:true,
    component: () => import(/* webpackChunkName: "admin" */ '../views/fontend/albumMaster.vue'),
  },
  {
    path: '/album/detail/:masterId/:activityTitle',
    name: 'detail',
    props:true,
    component: () => import(/* webpackChunkName: "admin" */ '../views/fontend/albumDetail.vue'),
  },
  {
    path: '/donet',
    name: 'donet',
    props:true,
    component: () => import(/* webpackChunkName: "admin" */ '../views/fontend/donet.vue'),
  },
  {
    path: '/donetInfo',
    name: 'donetInfo',
    props:true,
    component: () => import(/* webpackChunkName: "admin" */ '../views/fontend/donetInfo.vue'),
  },
  {
    path: '/donetMaterials',
    name: 'donetMaterials',
    props:true,
    component: () => import(/* webpackChunkName: "admin" */ '../views/fontend/donetMaterials.vue'),
  },
  {
    path: '/donetBill',
    name: 'donetBill',
    props:true,
    component: () => import(/* webpackChunkName: "admin" */ '../views/fontend/donetBill.vue'),
  },
  {
    path: '/order',
    name: 'order',
    props:true,
    component: () => import(/* webpackChunkName: "admin" */ '../views/fontend/order.vue'),
  },
  {
    path: '/materials',
    name: '/frontMaterials',
    props:true,
    component: () => import(/* webpackChunkName: "admin" */ '../views/fontend/materials.vue'),
  },
  {
    path: '/campaignMaster',
    name: '/frontCampaignMaster',
    props:true,
    component: () => import(/* webpackChunkName: "admin" */ '../views/fontend/campaignMaster.vue'),
  },
  {
    path: '/enrollMaster',
    name: '/enrollMaster',
    props:true,
    component: () => import(/* webpackChunkName: "admin" */ '../views/fontend/enrollMaster.vue'),
  },
  {
    path: '/enrollDetail/:enrollId',
    name: '/enrollDetail',
    props:true,
    component: () => import(/* webpackChunkName: "admin" */ '../views/fontend/enrollDetail.vue'),
  },
  {
    path: '/vacancies',
    name: '/frontVacancies',
    props:true,
    component: () => import(/* webpackChunkName: "admin" */ '../views/fontend/vacancies.vue'),
  },
  {
    path: '/report',
    name: '/frontReport',
    props:true,
    component: () => import(/* webpackChunkName: "admin" */ '../views/fontend/report.vue'),
  },
  {
    path: '/reportDetail/:reportId/:reportTitle',
    name: '/frontReportDetail',
    props:true,
    component: () => import(/* webpackChunkName: "admin" */ '../views/fontend/reportDetail.vue'),
  },
  {
    path: '/story',
    name: '/frontStory',
    props:true,
    component: () => import(/* webpackChunkName: "admin" */ '../views/fontend/story.vue'),
  },
  {
    path: '/video',
    name: '/frontVideo',
    props:true,
    component: () => import(/* webpackChunkName: "admin" */ '../views/fontend/video.vue'),
  },
  {
    path: '/news',
    name: '/frontNews',
    props:true,
    component: () => import(/* webpackChunkName: "admin" */ '../views/fontend/news.vue'),
  },
  {
    path: '/mail',
    name: '/frontMail',
    props:true,
    component: () => import(/* webpackChunkName: "admin" */ '../views/fontend/mail.vue'),
  },
  {
    path: '/storyDetail/:storyId/:storyTitle',
    name: '/frontStoryDetail',
    props:true,
    component: () => import(/* webpackChunkName: "admin" */ '../views/fontend/storyDetail.vue'),
  },
  {
    path: '/admin',
    name: 'admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin" */ '../views/backend/Admin.vue'),
  },
  {
    path: '/admin/banner',
    name: 'banner',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Banner" */ '../views/backend/Banner.vue'),
  },
  {
    path: '/admin/news',
    name: 'news',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Banner" */ '../views/backend/News.vue'),
  },
  {
    path: '/admin/enroll',
    name: 'enroll',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Banner" */ '../views/backend/Enroll.vue'),
  },
  {
    path: '/admin/article',
    name: 'article',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Banner" */ '../views/backend/Article.vue'),
  },
  {
    path: '/admin/aboutOrigin',
    name: 'aboutOrigin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Banner" */ '../views/backend/aboutOrigin.vue'),
  },
  {
    path: '/admin/aboutServe',
    name: 'aboutServe',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Banner" */ '../views/backend/aboutServe.vue'),
  },
  {
    path: '/admin/aboutOrganize',
    name: 'aboutOrganize',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Banner" */ '../views/backend/aboutOrganize.vue'),
  },
  {
    path: '/admin/aboutQuession',
    name: 'aboutQuession',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Banner" */ '../views/backend/aboutQuession.vue'),
  },
  {
    path: '/admin/activity',
    name: 'activity',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Banner" */ '../views/backend/Activity.vue'),
  },
  {
    path: '/admin/albumMaster/:activityId',
    name: 'albumMaster',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Banner" */ '../views/backend/albumMaster.vue'),
  },
  {
    path: '/admin/albumDetail/:masterId',
    name: 'albumDetail',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Banner" */ '../views/backend/albumDetail.vue'),
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Banner" */ '../views/backend/Login.vue'),
  },  
  {
    path: '/admin/donet',
    name: 'adminDonet',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Banner" */ '../views/backend/Donet.vue'),
  },
  {
    path: '/admin/donetInfo',
    name: 'adminDonetInfo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Banner" */ '../views/backend/donetInfo.vue'),
  },
  {
    path: '/admin/donetMaterials',
    name: 'adminDonetMaterials',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Banner" */ '../views/backend/donetMaterials.vue'),
  },
  {
    path: '/admin/donetBill',
    name: 'adminDonetBill',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Banner" */ '../views/backend/donetBill.vue'),
  },
  {
    path: '/admin/order',
    name: 'adminOrder',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Banner" */ '../views/backend/order.vue'),
  },
  {
    path: '/admin/manualOrder',
    name: 'manualOrder',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Banner" */ '../views/backend/manualOrder.vue'),
  },
  {
    path: '/admin/materials',
    name: 'materials',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Banner" */ '../views/backend/materials.vue'),
  },
  {
    path: '/admin/campaignMaster',
    name: 'campaignMaster',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Banner" */ '../views/backend/campaignMaster.vue'),
  },
  {
    path: '/admin/campaignDetail/:masterId/:enrollTitle',
    name: 'campaignDetail',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Banner" */ '../views/backend/campaignDetail.vue'),
  },
  {
    path: '/admin/Vacancies',
    name: 'Vacancies',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Banner" */ '../views/backend/Vacancies.vue'),
  },
  {
    path: '/admin/story',
    name: 'Story',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Banner" */ '../views/backend/Story.vue'),
  },
  {
    path: '/admin/report',
    name: 'Report',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Banner" */ '../views/backend/Report.vue'),
  },
  {
    path: '/admin/video',
    name: 'Video',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Banner" */ '../views/backend/Video.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

  
export default router;
