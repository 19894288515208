import { createStore } from 'vuex';
import {login} from './loginModule';
import {aboutMenu} from './aboutMenu';


const store =createStore({
  modules: {
    login,
    aboutMenu
  }
});
export default store