import router  from '../router';

const state = {
    isLogin: false || sessionStorage.getItem('isLogin'),
    user:null || sessionStorage.getItem('account') 
};

const actions = {
    login({commit},  {account} ) {
        commit('loginSuccess',{account});
        router.push('/admin');
    },
    logout({commit}) {
        commit('resetState');
        router.push('/login');
    }
};

const mutations = {
    resetState (state) {
        state.isLogin=false;
        state.user=null;
        sessionStorage.setItem('account', null);
        sessionStorage.setItem('isLogin', false);
    },
 
    loginSuccess(state,loginUser) {
        state.isLogin=true;
        state.user=loginUser.account;
        sessionStorage.setItem('account', loginUser.account);
        sessionStorage.setItem('isLogin', true);
    }
};

export const login = {
    namespaced: true,
    state,
    actions,
    mutations,
};
